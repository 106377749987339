import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Chart from 'chart.js/auto';
import sort from '@alpinejs/sort';
import collapse from '@alpinejs/collapse';
import EditorJS from '@editorjs/editorjs';
import EditorJSHeader from '@editorjs/header';
import EditorJSRawTool from '@editorjs/raw';
import EditorJSSimpleImage from '@editorjs/simple-image';
import EditorJSChecklist from '@editorjs/checklist';
import EditorJSList from '@editorjs/list';
import EditorJSEmbed from '@editorjs/embed';
import EditorJSTable from '@editorjs/table';
import EditorJSUnderline from '@editorjs/underline';
import * as Sentry from "@sentry/browser";

window.EditorJS = EditorJS;
window.EditorJSHeader = EditorJSHeader;
window.EditorJSRawTool = EditorJSRawTool;
window.EditorJSSimpleImage = EditorJSSimpleImage;
window.EditorJSChecklist = EditorJSChecklist;
window.EditorJSList = EditorJSList;
window.EditorJSEmbed = EditorJSEmbed;
window.EditorJSTable = EditorJSTable;
window.EditorJSUnderline = EditorJSUnderline;

Sentry.init({
    dsn:import.meta.env.VITE_SENTRY_DSN,
    tracesSampleRate: 0,
});

// window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.plugin(sort);

Livewire.start();

window.Chart = Chart;

window.redirectWarningListener = function (e) {
    var message = 'Weet je zeker dat je deze pagina wilt verlaten? Je hebt niet-opgeslagen aanpassingen!';

    e.returnValue = message;

    return message;
}
